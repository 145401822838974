*,*::before, *::after{
    margin:0 ;
    padding: 0;
  }
  
  /* .page-body{
    margin: 0;
    padding: 0 !important;
    overflow-x: hidden;
    min-height: 85vh;
  }

  .widgetsContainer{
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
  }

  .widgetContainer{
    width: 30%;
  }

  .invoiceParentContainer{
    padding: 2% 5%;
    justify-content: flex-start;
    align-items: center;
  }

  .dashboard-main-card{
    width: 100%;
    height: fit-content;
    padding: 1% 2%;
  }

  .invoiceHeadingContainer{
    padding: 0.75% 0;
    justify-content: space-between;
    align-items: center;
  }

  .hr-heading{
    width: 100%;
    border-top: 2px solid grey; 
    margin: 0;
  }

.rdt_TableCell, .rdt_TableCol{
    padding: 2px 0 !important;
  }
  
  .rdt_TableHeadRow{
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .rdt_TableCell .form-control{
    height: 18px !important;
    width: 60%;
    padding: 0.005rem 0.25rem !important;
  }
  
  .rdt_TableCell .form-control-sm{
    font-size: 0.75rem;
      font-weight: 400;
      text-align: justify ;
  }
  
  .sc-dhKdcB, .rdt_TableRow{
      margin-block: 2px !important;
      min-height: 50px !important;
      width: 100% !important;
  }

  .rdt_TableBody{
    width: 100% !important; 
  }

.mp-0{
    margin: 0;
    padding: 0;
  }
  .center{
    justify-content: center;
    align-items: center;
  }
  
  .d-flex-row{
    display: flex;
  }
  
  .d-flex-col{
    display: flex;
    flex-direction: column;
  }
  
  .overflow-x-hidden{
    overflow-x: hidden;
  }
  
  .overflow-y-hidden{
    overflow-y: hidden;
  }
  
  .hoverable{
    cursor: pointer;
  }
  
  .hidden {
    display: none;
  }
  
  .page-wrapper .page-header .header-wrapper .nav-right ul li svg {
    fill: white !important;
  } */

  .dashboardposmain{
   max-width: 900px; 
   margin: 0 auto; 
   margin-top: 20px;
  }

  .datatable-dashboardposmain > div > div >div > div{
    width: 840px !important;
  }
  
  .widget-1 .widget-round.unpaid{
    color: #dc3545;
  }

  .grid-container-dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create 5 equal columns */
    gap: 10px !important; /* Adjust the gap between boxes as needed */
  }

