:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --Dark-green-color: #005e32;
}

.supportaldashboard-header {
  font-size: 32px;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  color: var(--Dark-green-color) !important;
}

.supportaldashboard-subheader {
  font-size: 15px;
  font-weight: bold;
  white-space: pre-line !important; /* Preserve newline characters */
  margin-bottom: 0px !important;
  max-width: 800px;
}

.supportdashboard-box-text {
  font-size: 18px;
  font-weight: bold;
}

.supportdashboard-box-text-mobile {
  font-size: 12px;
  font-weight: bold;
}

.supportdashboard-box-mobile {
  width: 130px;
  height: 130px;
  background-color: #b6d8c5;
  border: 4px solid #005e32;
  border-radius: 18px;
}

/* input search columnwise */
.input-search-columnwise {
  padding: 5px !important;
  border: 2px solid #005e32 !important;
  border-radius: 0px !important;
  color: black !important;
  font-weight: bold !important;
}

.input-search-columnwise:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #05501b !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0rem #05501b !important;
}

/* export button */
.export-viewsupportrequest {
  width: auto !important;
  max-width: 1033px !important;
  text-align: right;
  margin: 0 auto;
}

.status-button{
  padding: 2px 4px !important;
  border-radius: 5px !important;
  font-size: 11px ;
}

.close-button{
  background-color: #009933 !important;
}

.pending-button{
  background-color: #FBB03B !important;
}

.hold-button {
  background-color:  #FE0606 !important;
}
.form-label-primary{
  color: #2f9933 !important;
}
.form-label-secondary{
  color: #005e32;
  font-weight: 600;
}
.radio.radio-primary {
  background: #B6D8C5;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #000;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #005E32;
  height: 42px;
  width: 42px;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: v#005E32;
  width: 20px;
  height: 20px;
  left: 11px;
  top: 11px;
}
span.digits {
  display: block;
  margin-left: 25px;
}
.edit-button, .delete-button {
  float: right;
  width: 30px;
  height: 30px;
  color: #005E32;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #005E32;
}
/* Non Checked Radio Button */
.radio-primary input[type="radio"] + label::before {
  border-color: #005E32;
  height: 42px;
  width: 42px;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #005E32;
  width: 20px;
  height: 20px;
  left: 11px;
  top: 11px;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #005E32;
}

.radio-button{
  margin-left: 10px;
}

.shadow-bottom {
  width: 140px;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.shadow-bottom:hover {
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.dzu-dropzone {
  border: 2px dashed #000000 !important;
  background: rgb(182 216 197);
  overflow: auto !important;
}
.dzu-inputLabel {
  color: #005c33 !important;
  text-align: center;
  margin-bottom: 0;
}
.flex-container{
  display: flex;
  margin: 15px 0px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.flex-container>div {
  margin-left: 10px;
}
.modal-header
{
  padding: 10px 15px !important;
  // background: #26994e;
  background: #005e32;
  color: #fff;
  border-radius: 0px;
}
.modal-title{
  font-size: 18px;
}
.error-message{
  color: #470000;
  font-weight: 400;
  font-size: 17px !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
  border-top: 0px;
  min-height: 300px;
  background: #fff;
}
.record-btn {
  width: 250px;
  background-color: #ffb100 !important;
  border-color: #ffb100 !important;
  font-weight: bold;
  text-align: left;
  padding: 2px !important;
}

.record-img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.record-btn:hover {
  background-color: #ffb100 !important;
  border-color: #ffb100 !important;
}
// .form-control:focus
// {
//   border-color: #c2dfcf !important;
//   box-shadow: 0 0 0 0.25rem #c2dfcfbf !important;
// }
.form-select:focus
{
  border-color: #c2dfcf !important;
  box-shadow: 0 0 0 0.25rem #c2dfcfbf !important;
}
.form-handling-open-close{
  position: absolute;
  top: 50%;
  padding: 15px 5px;
}
.header-h1{
  font-size: 1.50rem;
}
.header-p{
  font-size: 21px;
}
.table th{
  font-size: 11px;
  font-weight: 600;
}
.supportlist-table{
  border-collapse: separate;
  border-spacing: 0 0.5em;
  background-color: white;
}
.supportlist-table th,td{
  border: none !important;
}
.supportlist-table td{
  background-color: #e8e7e7 !important;
  color: #136152;
  font-size: 12px;
  padding: 10px 10px !important;
}
.supportlist-table input{
  width: 100%;
  padding: 6px !important;
  border: 3px solid #b6d8c5 !important;
  border-radius: 8px !important;
  margin-top: 05px;
}

.supportlist-table input:focus{
  color: #212529 !important;
    background-color: #fff !important;
    border: 2px solid #005e32  !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem #005e32 !important;
    transition: all 0.3s ease-in-out;
}

/* support portal detail page */
.app-support-detail {
  display: flex;
}

.left-bar {
  /* width: 320px; */
  background-color: #b6d8c5;
  transition: width 0.3s ease; /* Add transition */
  min-height: 100vh;
  /* padding: 20px; */
}

.left-bar.closed {
  width: 0; /* Collapse left bar width */
  padding: 0px;
}

.right-bar {
  flex: 1;
  overflow-y: auto; /* Enable vertical scroll */
  /* padding: 10px;
  border: 1px solid gray; */
  transition: margin-left 0.3s ease; /* Add transition */
}
.right-ineer-content{
  margin-top: 35%;
}
.app-support-detail.closed-left .right-bar {
  margin-left: 0; /* Reset margin when left bar is closed */
}
.app-support-detail .accordion {
  --bs-accordion-bg: #b6d8c5 !important;
  --bs-accordion-border-color: #b6d8c5 !important;
  --bs-accordion-btn-focus-border-color: #b6d8c5 !important;
}
.accordion-button:not(.collapsed) {
  background-color: #b6d8c5;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(#b6d8c5);
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(#b6d8c5);
  outline: 0;
  box-shadow: none;
}
.content{
  padding: 20px;
}
.content-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100vh;
}
.button-container {
  display: flex;
  align-items: flex-start; /* Vertical center alignment */
  background: #b6d8c5;
}
.button-container .btn-link {
  color: #000 !important;
  font-size: 20px !important;
}
.right-ineer-content .label{
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0px;
  color: #005E32;
}
.label-container{
  margin: 10px 0px;
}
.btn-show{
  padding: 0px;
  margin-left: 5px;
  text-decoration: none;
  color: #2b984e;
}
.tab-support-details ul{
  background: #eae8e8;
  padding: 10px;
}
.tab-support-details .card .card-header{
  background-color: #daebe2 !important;
}
.tab-support-details .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  background-color: #b6d8c5;
}
.table-attachment{
  border-collapse: separate;
  border-spacing: 0 0.5em;
  background-color: white;
}
.table-attachment  th{
  //padding-top: 12px;
  //padding-bottom: 12px;
  //text-align: center;
  background-color: #B6D8C5;
  color: #005E32;
  border: 0;
}
.table-attachment  td{
  background-color: #e8e7e7 !important;
  color: #136152;
  font-size: 14px;
  //padding: 10px 10px !important;
}
.TR-FirstCell{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.TR-LastCell{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.btn-success{
  background-color: #2b984e !important;
  border-radius: 5px !important;
}
.btn-warning{
  background-color: #ffb100 !important;
  border-radius: 5px !important;
}
.btn-danger{
  background-color: #ff3131 !important;
  border-radius: 5px !important;
}
.btn svg{
  vertical-align: inherit;
}
.TR-LastCell button{
  border-radius: 50% !important;
  padding: 5px !important;
  margin-right: 5px !important;
}

.container-audio{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.accordion-button:not(.collapsed){
  color:#000 !important; 
}
.reply-icon{
  transform: rotate(40deg);
  color: #fff;
}
.timeline-body-content img {
  max-width: 200px !important;
  height: auto !important;
}
.media-body img {
  max-width: 200px !important;
  height: auto !important;
}
.badge-danger{
  background-color: #fe0606 !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
}
.badge-success{
  background-color: #093 !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
}
.badge-info{
  background-color: #60c0e2 !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
}
.badge-primary{
  background-color: #fbb03b !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
}

.text-wrap-common{
  text-align: start !important;
 overflow: hidden !important;
 white-space: normal !important;
}

//#============ add by ankit on 15/03/2024
.welcome-to {
  text-align: center;
  font-size: 50px;
  color: #005e32;
}
//#============ End Here


//#========== Quotation CSS


/* media query  */
@media (max-width: 600px) {
  .app-support-detail {
    display: flex;
    flex-direction: column !important;
  }
  .left-bar {
    min-height: 0 !important;
  }
  .right-ineer-content {
    margin-top: 5% !important;
  }
  .button-container {
    display: none !important;
  }
  .app-support-detail h4 {
    font-size: 15px !important;
    font-weight: 500 !important;
  }
  .img-50 {
    width: 30px !important;
  }
  .image-container img {
    width: 50px !important;
    height: 50px !important;
  }
  .process-box {
    height: 60px !important;
    width: 60px !important;
  }
  .horizontal-line {
    flex-grow: 0.5 !important;
  }
  .process-yellow-box{
    height: 60px !important;
    width: 60px !important;
  }
  .horizontal-dotted-line{
    flex-grow: 0.5 !important;
  }
  .process-yellow-box + p, .process-box + p {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .header-h1{
    color: #373838;
    font-size: 36px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 54px;
    word-wrap: break-word 
  }
  .content-container {
    display: inline !important;
    min-height: 0 !important;
  }
  .header-p{
    color: black;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 27px;
    word-wrap: break-word 
  }
  .supportaldashboard-header {
    font-size: 17px;
  }
  .media-body img {
    max-width: 100px !important;
    height: auto !important;
  }
  .timeline-body-content img {
    max-width: 100px !important;
    height: auto !important;
  }
  .supportaldashboard-subheader {
    white-space: normal !important; /* Allow text to wrap without preserving newline characters */
    font-size: 12px;
  }
  .flex-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .flex-container > div {
    margin-left: 0;
    margin-top: 10px; /* Adjust as needed */
  }
  .radio.radio-primary
  {
    background: #B6D8C5;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #000;
    display: flow-root;
  }
  .radio-primary input[type="radio"] + label::before
  {
    border-color: #005E32;
    height: 25px;
    width: 25px;
  }
  span.digits
{
  display: block;
  margin-left: 15px;
}
.radio label::before
{
 margin-left: -8px;
}
.radio-primary input[type="radio"]:checked + label::after
{
  background-color: #005E32;
  width: 14px;
  height: 14px;
  left: 17px;
  top: 5px;
}
.radio-primary input[type="radio"]:checked + label::before
{
  border-color: #005E32;
  height: 25px;
  width: 25px;
}
span.digits
{
    display: block;
    margin-left: 20px;
    word-wrap: anywhere;
  }
  .edit-button, .delete-button
{
  float: right;
  width: 25px;
  height: 25px;
  color: #005E32;
}
}
