/* .supportlist-table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
   */
  .supportlist-table td,
  .supportlist-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* .supportlist-table tr:nth-child(even) {
    background-color: #f2f2f2  !important;
  }
  
  .supportlist-table tr:hover {
    background-color: #ddd !important;
  } */
  
  .supportlist-table  th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #B6D8C5;
    color: #005E32;
  }