*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

.page-body {
  margin: 0;
  padding: 0 !important;
  overflow-x: hidden;
  min-height: 85vh;
  /* background-color: aqua; */
}

.green-header {
  font-size: 23px;
  font-weight: bold !important;
  color: #005e32 !important;
}

.login-header {
  font-size: 15px;
  font-weight: bold;
}

.dashboard-center {
  height: 80vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.common-para {
  margin-bottom: 0rem !important;
}

.main-card {
  border: 5px solid #005e32;
  border-radius: 12px !important;
}

.signin-btn {
  background-color: #005e32 !important;
  border-color: #005e32 !important;
  color: white;
  border-radius: 100px;
  width: 286px;
  height: 68px;
  font-weight: bold;
  font-size: 23px;
}

.signin-btn:hover {
  background-color: #098048 !important;
  border-color: #098048 !important;
}

.displaySection {
  height: 85vh;
  width: 38vw;
  /* background-color: pink; */
}

.detailSection {
  /* height: 85vh; */
  /* width: 62vw; */
  /* background-color: yellow; */
}

/*  display section -left  */

.categoryContainer {
  height: 100%;
  width: 10vw;
  /* background-color: black; */
}

.productContainer {
  height: 100%;
  /* width: 28vw; */
  /* background-color: blue; */
}

.categoryContainerBody {
  height: 100%;
  width: 10vw;
  /* background-color: blueviolet; */
}

.productContainerBody {
  height: 100%;
  /* width: 28vw; */
  /* background-color: cadetblue; */
}

.categorySelectContainer {
  height: 8.5vh;
  width: 100%;
  /* background-color: coral; */
  justify-content: center;
  align-items: flex-start;
  padding: 0 0.2vw;
}

.categoryItemsContainer {
  display: flex;
  flex-direction: column;
  height: 100% - 8.5vh;
  /* background-color: blanchedalmond; */
  padding: 0 0.1vw;
  overflow-y: scroll;
}

.categoryItemsContainer::-webkit-scrollbar {
  width: 6px;
}

.categoryItemsContainer::-webkit-scrollbar-track {
  background: rgba(244, 252, 252, 0.5);
}

.categoryItemsContainer::-webkit-scrollbar-thumb {
  background: rgb(219, 228, 252);
}

.productSearchContainer {
  height: 9vh;
  width: 100%;
  /* background-color: burlywood; */
  justify-content: flex-start;
  align-items: center;
  padding: 0.65vh 1vw;
}

.productItemsContainer {
  /* height: 100% -9vh; */
  /* max-height: 76.5vh; */
  /* width: 100%; */
  /* background-color: darkblue; */
  /* padding: 1vh 0.1vw 1vh 0.8vw; */
  /* flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: flex-start;
  align-items: flex-start; */
}

/* .productItemsContainer::-webkit-scrollbar{
  width: 6px;
 }

.productItemsContainer::-webkit-scrollbar-track {
  background: rgba(244,252,252,0.5); 
}

.productItemsContainer::-webkit-scrollbar-thumb {
  background: rgb(219,228,252); 
} */

/*  details section - right  */

.rightMenu {
  /* min-height: 5vh;
  width: 100%;
  padding: 1vh 2vw;
  align-items: left; */
}

.rightMenuHeader {
  /* width: 90%;
  justify-content: right; */
}

.cust-toggle-line {
  /* width: max-content;
  justify-content: space-evenly;
  align-items: center; */
}

.shippingCheckboxContainer {
  /* width: max-content;
  margin: 0 8%;
  align-items: center; */
}

.shippingCheckboxContainer input {
  height: 15px;
  width: 15px;
  margin: 0 5px;
}

.billContainer {
  min-height: 10vh;
  height: fit-content;
  width: 100%;
  /* background-color: aliceblue; */
  /* flex-direction: row-reverse; */
  align-items: center;
}

.customerDetailsContainerBody {
  height: fit-content;
  /* width: 57vw; */
  padding: 1vh 1vw;
  /* background-color: beige; */
}
.bill {
  height: auto;
  width: 100%;
}

.bill::-webkit-scrollbar {
  width: 6px;
}

.bill::-webkit-scrollbar-track {
  background: rgba(244, 252, 252, 0.5);
}

.bill::-webkit-scrollbar-thumb {
  background: rgb(219, 228, 252);
}

.billContainerBody {
  height: auto;
  width: 100%;
  /* background-color: white; */
}

.contactContainer,
.billingContainer,
.shippingContainer {
  height: 100%;
  width: 33.33%;
  /* background-color: cornflowerblue; */
  justify-content: center;
  align-items: center;
}

/* category items style */
.categoryItemContainer,
#cat-gridId {
  height: 4vh;
  width: 100%;
}

.categoryItemContainer {
  width: 70% !important;
}

#cat-gridId {
  flex: 0 0 10%;
  margin: 0.15%;
  min-height: 6vh;
  border: 3px solid rgb(231, 230, 230);
  /* border-left: 2.5px solid rgb(231, 230, 230);
  border-bottom: 2.5px solid rgb(231, 230, 230); */
  /* border-radius: 10px; */
  padding: 0 6px;
  justify-content: center;
  align-items: center;
}

.cat-gridId.selected {
  background-color: #ebffef; /* Gray background for the selected category */
  position: relative; /* Enable positioning for the green line */
}

.cat-gridId.selected:before {
  content: ""; /* Create a pseudo-element for the green line */
  position: absolute; /* Position the line absolutely within the selected category */
  top: 0; /* Start the line at the top */
  left: 0; /* Position the line on the left edge */
  width: 4px; /* Set the line width */
  height: 100%; /* Make the line the full height */
  background-color: var(--theme-default); /* Set the line color */
  background-color: #005e32; /* Set the line color */
}

.category-name {
  height: 90%;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.6;
  margin-bottom: 0.4rem;
  text-align: center;
  /* color: var(--theme-default); */
}

.category-name.selected {
  /* color: var(--theme-default); */
  color: #005e32;
  font-weight: bolder !important;
}
.cat-img {
  height: 80%;
  width: 35%;
  margin: 0;
  border-radius: 7px;
}

/* Product search */
.productSearchInputGroup {
  height: 40px;
  position: relative;
}

.productSearchInputGroup > input {
  font-size: 0.8rem;
}

.barcode-input > input {
  font-size: 0.9rem;
}

.form-control {
  width: 100%;
  height: 40px;
}

.fa-search {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}

.fa-search {
  color: lightgray;
  font-size: 15px;
}

.fa-search:hover {
  color: #8e8c8c;
}

.css-1fdsijx-ValueContainer {
  padding: 4px !important;
}

/* product items*/

.loaderContainer {
  height: 6vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#prod-gridId {
  height: 110px;
  width: 85px;
  margin: 1.25%;
}

.prod-img {
  height: 120px !important;
  width: 120px !important;
  border-radius: 10px;
  border: 1px solid rgb(222, 220, 220) !important;
}

.product-name {
  /* width: 120px;
  height: 20px; */
  max-height: 20px;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.6;
  margin-bottom: 0.4rem;
  text-align: center;
}

/* Conatact, Address  */
.contact-group,
.billing-group,
.shipping-group {
  /* width: 250; */
  justify-content: center;
  /* margin: 2%; */
}
.contact-p,
.billing-p,
.shipping-p {
  /* display: inline-block;
  width: fit-content;
  margin-bottom: 0 !important; */
}

.contact-button,
.billing-button,
.shipping-button {
  height: 22px;
  width: 22px;
}

/* toggle switch */
#toggleSwitch {
  /* Customize size */
  transform: scale(1.2);
  background-color: #cad0d5;
  margin: 5px 15px;
}

#toggleSwitch:checked {
  background-color: #7366ff;
}

/* draft */
.draft-button {
  height: 40px;
  width: 80px;
  padding: 0 10px;
  margin: 0 10px;
  justify-self: flex-start;
}

.rounded-pill {
  right: -15px !important;
  padding: 2px 4px !important;
  /* font-size: 11px; */
}

.Toastify__toast--success {
  background-color: #07bc0c !important;
}

.modal-header {
  background-color: rgb(231, 231, 231);
}

.draft-content-container {
  /* padding: 1% 5%; */
  /* padding: 20px; */
}

.draft-content-container {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.eachDraftContainer {
  width: fit-content;
  height: fit-content;
  margin: 5px 5px;
}

.draftBox {
  background-color: rgba(211, 211, 211, 0.381);
  padding: 0.5rem;
  border: 2px solid green;
}

.draftDetails-p {
  margin: 0;
  font-size: 13px;
}

.draftNumber {
  text-align: center;
  font-size: larger;
  font-weight: 800;
}

.draftOptions {
  justify-content: flex-end;
  align-items: center;
}

.draft-total-amt {
  color: green;
  font-weight: 600;
}

/* table */
.rdt_TableCell,
.rdt_TableCol {
  /* padding: 2px 0 !important; */
}

.bill > div > div > div > div > div > div {
  padding: 2px 0 !important;
}

.rdt_TableHeadRow {
  position: sticky;
  top: 0;
  z-index: 1;
}

.rdt_TableCol:nth-child(4),
.rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(4)
  .rdt_TableCol_Sortable {
  /* justify-content: flex-start !important; */
}

.rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(1)
  .rdt_TableCol_Sortable {
  justify-content: center !important;
}

.rdt_TableCell .form-control {
  height: 32px !important;
  width: 60%;
  padding: 0.005rem 0.25rem !important;
}

.rdt_TableCell .form-control-sm {
  font-size: 0.75rem;
  font-weight: 400;
  text-align: justify;
}

.qty-button-grp {
  width: 75px;
  height: fit-content;
}

.qty-button-style {
  height: 28px !important;
  width: 22px !important;
  border: 0.5px solid rgb(222, 222, 222);
  border-radius: 3px;
  background-color: white !important;
}

.sc-dhKdcB,
.rdt_TableRow {
  margin-block: 2px !important;
  min-height: 50px !important;
}

/* below bill */
.extraDetailsContainer {
  height: fit-content;
  width: 100%;
}

.resultContainer {
  height: auto;
  width: 100%;
  padding: 2% 7%;
  flex-flow: row-reverse;
}

.buttonsBillContainer {
  height: fit-content;
  width: 100%;
  justify-content: flex-end;
  /* align-items: flex-start; */
  gap: 20px;
  padding: 1.5% 0;
}

.resultTable {
  height: fit-content;
  width: 50%;
  background-color: white;
}

.resultTable td {
  padding: 0.8% 7%;
}

.resultTable th {
  font-size: larger;
  font-stretch: expanded;
  padding: 2% 5%;
}

/* zig- zag border*/
.box {
  --mask: conic-gradient(
        from 135deg at top,
        #0000,
        #000 1deg 89deg,
        #0000 90deg
      )
      top/8.00px 51% repeat-x,
    conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg)
      bottom/8.00px 51% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

/* buttons */
.buttonContainer {
  height: fit-content;
  width: fit-content;
}

.svgButtonContainer {
  padding: 2px;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  align-items: flex-end;
  align-self: center;
  margin-top: 4px;
}

.bill-button-text {
  font-size: medium;
  font-weight: 600;
  margin-left: 6px;
}

.buttonBill {
  /* width: 180px; */
  font-size: medium;
  padding: 8px 35px;
  justify-content: center !important;
  align-items: center;
  display: flex !important;
}

.buttonBill:hover {
  filter: brightness(0.8);
}

/* modal */
/* .modal-content{
  min-height: 400px;
  max-height: 800px;
  overflow-y: scroll;
} */

/* utils */
.mp-0 {
  margin: 0;
  padding: 0;
}
.center {
  justify-content: center;
  align-items: center;
}

.d-flex-row {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.hoverable {
  cursor: pointer;
}

.hidden {
  display: none;
}

/**/
.page-wrapper .page-header .header-wrapper .nav-right ul li svg {
  fill: white !important;
}

/* add by umang  */
.overflow-y-auto {
  max-height: 80vh;
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 1px !important; /* Adjust the width as needed */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust the color as needed */
}

/* Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent; /* Adjust the color as needed */
}

.show-scrollbar {
  overflow-y: scroll;
}

.my-grid {
  display: grid;
  grid-template-columns: 12% 22% 66%;
}

/* .my-grid {
  display: grid;
  grid-template-columns: 150px 230px auto;
} */

/* @media screen and (max-width: 768px) {
  .my-grid {
    grid-template-columns: 12% 22% 66%; 
  }
}

@media screen and (max-width: 480px) {
  .my-grid {
    grid-template-columns: 12% 22% 66%;
  }
} */

.action-button svg {
  vertical-align: middle !important;
}

.modal-button {
  padding: 4px 8px;
}

.customerdetail-switch > div {
  padding-left: 0em !important;
}

/* @media (min-width: 768px){
  .col-md-2 {
    flex: 0 0 auto;
    width: 12%;
}
}

@media (min-width: 768px){
  .col-md-3 {
    flex: 0 0 auto;
    width: 22%;
}
}

@media (min-width: 768px){
  .col-md-7 {
    flex: 0 0 auto;
    width: 66%;
}
} */
.buttonBill:active {
  color: #fff !important;
}

.draft-button:active {
  color: #fff !important;
}

/* General navbar styles */

.navbar-pos {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  background-color: #fff; /* Change background color if needed */
  padding: 1rem;
}

.navbar-nav-pos {
  display: flex;
  list-style: none;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.nav-item {
  /* Adjust margin-right based on your preferences */
  margin-right: 1rem;
}

.nav-link-pos {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  font-weight: 500; /* Adjust font weight as needed */
}

/* Styles for "Cleaning Essentials" button */
.nav-link-group {
  background-color: #f2f2f2; /* Change background color */
  border-radius: 4px; /* Adjust border radius */
  padding: 0.5rem 1rem;
  font-weight: bold; /* Adjust font weight */
  color: #333; /* Adjust font color */

  &:hover {
    background-color: #e6e6e6; /* Adjust hover background color */
  }
}

/* Styles for "Other" button and dropdown menu */
.dropdown-toggle {
  background-color: transparent;
  border: none;
  padding: 0;
  /* font-weight: bold;  */
  /* color: #333 !important; */
  cursor: pointer;

  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  &:hover:after {
    border-top-color: #333; /* Adjust hover border color */
  }
}

.dropdown-menu {
  background-color: #fff; /* Change background color */
  border: 1px solid #ddd; /* Adjust border color */
  border-radius: 4px; /* Adjust border radius */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Adjust box shadow */
  padding: 0.5rem;
  position: absolute;
  top: 100%; /* Position dropdown menu below the button */
  left: 0;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 15px;
    border-width: 8px 8px 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.dropdown-item-pos {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  text-align: center;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  clear: both;
  font-weight: 400;
  white-space: nowrap;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);

  &:hover {
    /* background-color: #ebffef; */
    cursor: pointer;
  }
}

.dropdown-content {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow-y: auto !important;
  max-height: 150px; /* Set maximum height for the dropdown */
  width: 200px; /* Set a fixed width for the dropdown */
}

.dropdown-more {
  position: relative;
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  text-align: center;
  display: block;
  width: 100%;
  clear: both;
  font-weight: lighter;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover {
    background-color: #f2f2f2; /* Adjust hover background color */
    cursor: pointer;
  }
}

.dropdown-item-pos.selected-item {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  text-align: center;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  clear: both;
  font-weight: 400;
  white-space: nowrap;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
  background-color: #d2dbd4;
  font-weight: bolder;
  /* #ebffef */
}

.attr-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qty-input-grp-red > input:focus {
  box-shadow: 0 0 0 0.25rem #fc4438;
  border-color: #fc4438;
}

.qty-input-grp-red > input {
  box-shadow: 0 0 0 0.25rem #fc4438;
  border-color: #fc4438;
}

.navbar-pos {
  /* position: fixed; */
  top: 135px;
  left: 0;
  width: 100%;
  z-index: 100;
}

.dropdown-content > ul > li {
  padding: 0.5rem;
}

.more-horizontal {
  margin: 0 !important;
}

.vertical-line::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 98%;
  border-left: 1px solid #000; /* Adjust the color and style as needed */
  transform: translateX(-50%);
  min-height: 70vh;
}

.center-nocategory {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.iconButton {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
}

.icon {
  margin-right: 10px;
}

.bill > div > div > div > :nth-child(2) {
  min-height: 62px !important;
  width: 100% !important;
  margin-block: 2px !important;
  background-color: #f5f4ef !important;
  color: #098048 !important;
  font-size: 12px !important;
}

.footer-header {
  font-size: 15px;
  font-weight: bold !important;
  color: #005e32 !important;
}

/* for invoice print */

.print-only {
  visibility: hidden;
}

.pdf-only {
  visibility: hidden;
}
@media print {
  .print-only {
    display: block;
  }
}

/* body {
  margin: 0;
  padding: 0;
} */

.receipt {
  width: 400px;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
  border: 1px solid #ddd;
  font-family: sans-serif;
}

.receipt h1 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.receipt p {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.receipt table {
  width: 100%;
  border-collapse: collapse;
}

.receipt table th,
.receipt table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;
  font-size: 12px;
}

.receipt table th {
  font-weight: bold;
}

.inr-font {
  font-size: 10px;
  font-weight: bold;
}

/* .product-img {
  position: relative;
  overflow: hidden;
} */

.prod-img:hover {
  transform: scale(1.1); /* Increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow on hover */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-img:hover .product-name {
  /* transform: scale(1.1);  */
  transition: font-size 0.3s ease;
  margin-top: 5px;
  font-size: 11px;
}
