.green-header {
  font-size: 23px;
  font-weight: bold !important;
  color: #005e32 !important;
}

.login-header {
  font-size: 15px;
  font-weight: bold;
}

.dashboard-center {
  height: 80vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.common-para {
  margin-bottom: 0rem !important;
}

.main-card {
  border: 5px solid #005e32;
  border-radius: 12px !important;
}

.signin-btn {
  background-color: #005e32 !important;
  border-color: #005e32 !important;
  color: white;
  border-radius: 100px;
  width: 286px;
  height: 68px;
  font-weight: bold;
  font-size: 23px;
}

.signin-btn:hover {
  background-color: #098048 !important;
  border-color: #098048 !important;
}

.sub-header {
  font-size: 13px;
  margin-bottom: 0.5rem !important;
}

.order-box {
  width: 243px;
  height: 243px;
  background-color: #b6d8c5;
  border: 4px solid #005e32;
  border-radius: 18px;
}

.order-box:hover {
  background-color: #dddddd;
}

.order-box-text {
  font-size: 25px;
  font-weight: bold;
}

.order-box-selected {
  width: 243px;
  height: 243px;
  background-color: #005e32;
  border: 4px solid #005e32;
  border-radius: 18px;
  color: white;
}

.order-box-mobile {
  width: 150px;
  height: 150px;
  background-color: #b6d8c5;
  border: 4px solid #005e32;
  border-radius: 18px;
}

.order-box-text-mobile {
  font-size: 15px;
  font-weight: bold;
}

.order-box-selected-mobile {
  width: 150px;
  height: 150px;
  background-color: #005e32;
  border: 4px solid #005e32;
  border-radius: 18px;
  color: white;
}

.order-box-selected-img {
  filter: brightness(0) invert(1);
  margin-top: 1rem !important;
}

.dashboardbox-img {
  width: 170px;
  height: 170px;
}

.dashboardbox-img-mobile {
  width: 90px;
  height: 90px;
}

/* start datatable  */
/* .rdt_TableHeadRow {
  font-size: 16px !important;
  background-color: #b6d8c5 !important;
  color: #005e32 !important;
  font-weight: bolder !important;
  min-height: 62px !important;
} */

.rdt_TableRow {
  min-height: 62px !important;
  width: auto !important;
  margin-block: 2px !important;
  background-color: #f5f4ef !important;
  color: #098048 !important;
  font-size: 12px !important;
}

.rdt_Table {
  justify-content: center !important;
  align-items: center !important;
}

.rdt_Pagination {
  background-color: transparent !important;
  justify-content: center !important;
  border-top-style: none !important;
}

.custom-data-table {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(4) {
  justify-content: flex-start !important;
} */

/* #row-157 :nth-child(1) {
  min-width: 33px !important;
} */

.rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable
  > div {
  font-weight: bold !important;
}

.custom-data-table :first-child {
  width: auto !important;
}

/* End datatable  */

.addicon {
  width: 40px;
  height: 40px;
}

/* input field  */
.register-full {
  width: 100%;
  padding: 6px !important;
  border: 3px solid #b6d8c5 !important;
  border-radius: 8px !important;
}

.input-register {
  color: black !important;
  font-weight: bold !important;
  background-color: white;
}

.input-gray {
  width: 100%;
  padding: 6px !important;
  border: 3px solid gray !important;
  border-radius: 8px !important;
  color: black !important;
  font-weight: bold !important;
  background-color: var(--bs-secondary-bg) !important;
}

.input-register:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #9addae !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem #9addae !important;
}

.column-register {
  color: black !important;
  font-weight: bold !important;
  background-color: white;
}

.column-register:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border: 2px solid #005e32 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem #005e32 !important;
  transition: all 0.3s ease-in-out;
}

.timesheet-label {
  color: #005e32 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.timesheet-sublabel {
  font-size: 13px;
  background-color: transparent;
}

.select-font {
  font-size: 13px !important;
}

/* date-time picker  */
.react-datepicker__time-container {
  width: 150px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 150px !important;
}

.react-datepicker__time-list {
  padding-inline-start: 0 !important;
}

.react-datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #005e32 !important;
}

.react-datepicker .react-datepicker-time__header {
  color: #005e32 !important;
  padding-block: 5px !important;
}

.datetimepicker-input {
  width: 100%;
  padding: 6px !important;
  border: 3px solid #b6d8c5 !important;
  border-radius: 8px !important;
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  /* font-size: 12px !important; */
}

.input-group-text {
  /* width: 100%; */
  padding: 0.22rem 0.75rem !important;
  border: 3px solid #b6d8c5 !important;
  border-radius: 8px !important;
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.input-group-text:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #9addae !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem #9addae !important;
}

.react-datepicker .react-datepicker__current-month {
  color: #005e32 !important;
}

.react-datepicker__navigation {
  background-color: #005e32 !important;
}

.react-datepicker .react-datepicker__day--today {
  background-color: transparent !important;
  color: black !important;
}

.react-datepicker .react-datepicker__day--selected {
  background-color: #005e32 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #005e32 !important;
}

.react-datepicker .react-datepicker-year-header {
  color: #005e32 !important;
}

.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #005e32 !important;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: #005e32 !important;
}

.react-datepicker__month .react-datepicker__month-text {
  width: 4.2rem !important;
}

/* submitbutton */
.submit-btn {
  background-color: #005e32 !important;
  border-color: #005e32 !important;
  color: white;
  border-radius: 100px;
  width: 286px;
  height: 68px;
  font-weight: bold;
  font-size: 23px;
}

.submit-btn:hover {
  background-color: #098048 !important;
  border-color: #098048 !important;
}

.submit-btn:active {
  background-color: #098048 !important;
  border-color: #098048 !important;
}

/* datatable  */
.trackorder-row {
  background-color: #f5f4ef;
}

.rdt_TableHeadRow {
  font-size: 13px !important;
  background-color: #b6d8c5 !important;
  color: #005e32 !important;
  font-weight: bolder !important;
  min-height: 62px !important;
}

.rdt_Table {
  justify-content: center !important;
  align-items: center !important;
}

.rdt_Table > div {
  background-color: transparent !important;
}

.rdt_Pagination {
  justify-content: center !important;
  border-top-style: none !important;
}

.rdt_TableHeader {
  background-color: transparent !important;
}

.rdt_TableHeader :nth-child(2) {
  justify-content: center !important;
  margin-left: 100px;
}

.custom-data-table {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.custom-data-table :first-child {
  width: auto !important;
  white-space: normal !important;
}

.rdt_TableCol_Sortable {
  text-align: start !important;
}

.table-extra {
  text-align: center;
  margin: 0px auto;
  position: relative;
  border-radius: inherit;
  min-height: 0px;
  max-width: 1200px;
  overflow: scroll;
  width: 100%;
}

.table-extra :first-child {
  width: auto !important;
}

/* expandable  */
.expand-header {
  font-size: 15px;
  color: #005e32;
  font-weight: bold;
}

.expand-subheader {
  color: #098048;
}

.report-txt {
  color: #005e32;
  font-size: 20px;
  font-weight: bold;
}

/* vieworderreport */
.report-box {
  width: 130px;
  height: 130px;
  background-color: #b6d8c5;
  border: 4px solid #005e32;
  border-radius: 18px;
}

.report-box:hover {
  background-color: #dddddd;
}

.report-box-text {
  font-size: 8px;
  font-weight: bold;
}

.report-box-selected {
  width: 130px;
  height: 130px;
  background-color: #005e32;
  border: 4px solid #005e32;
  border-radius: 18px;
  color: white;
}

.report-box-selected-img {
  filter: brightness(0) invert(1);
  margin-top: 1rem !important;
}

.report-img {
  width: 50px;
  height: 50px;
}

/* header  */
.btn-div {
  gap: 10px;
}

.btn-icon {
  width: 30px !important;
  height: 30px !important;
}

.btnmobile-icon {
  width: 18px !important;
  height: 18px !important;
}

.headerbtn-common {
  font-size: 15px;
  padding: 5px !important;
  border-radius: 5px;
}

.headerbtn-mobile {
  font-size: 10px;
  padding: 5px !important;
  border-radius: 5px;
}

.punchinbtn {
  background-color: #009933 !important;
  border-color: #009933 !important;
  color: white !important;
}

.punchinbtn:hover {
  background-color: rgb(17, 173, 69) !important;
  border-color: rgb(17, 173, 69) !important;
}

.punchinbtn:active {
  background-color: rgb(17, 173, 69) !important;
  border-color: rgb(17, 173, 69) !important;
}

.punchoutbtn {
  background-color: #ffb100 !important;
  border-color: #ffb100 !important;
  color: white !important;
}

.punchoutbtn:hover {
  background-color: #e2ab2c !important;
  border-color: #e2ab2c !important;
}

.punchoutbtn:active {
  background-color: #e2ab2c !important;
  border-color: #e2ab2c !important;
}

.breakbtn {
  background-color: #ff3131 !important;
  border-color: #ff3131 !important;
  color: white !important;
}

.breakbtn:hover {
  background-color: #e24444 !important;
  border-color: #e24444 !important;
}

.breakbtn:active {
  background-color: #e24444 !important;
  border-color: #e24444 !important;
}

/* radio button  */
.delivery-radiobtn > input {
  width: 20px !important;
  height: 20px !important;
}

.delivery-radiobtn > label {
  font-size: 16px !important;
}

.form-check-input:checked {
  background-color: #005e32;
  border-color: #005e32;
}

.form-check .form-check-input {
  margin-left: 0em;
  /* margin-top: 0em; */
  margin-bottom: 0em;
}

.form-check-label {
  margin-left: 4px;
  text-align: left;
  /* font-size: 23px;
    font-weight: bold;
    color: #098048; */
}

.searchbtn {
  background-color: #005e32 !important;
  border-color: #005e32 !important;
}

.searchbtn:hover {
  background-color: #098048 !important;
  border-color: #098048 !important;
}

.searchbtn:active {
  background-color: #098048 !important;
  border-color: #098048 !important;
}

.export-link {
  color: white !important;
}

/* sidebar  */

.sidebar-icon {
  color: rgba(82, 82, 108, 0.8) !important;
}

.toggle-sidebar > svg:hover {
  stroke: #005e32 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active):hover
  span {
  /* color: #7366ff; */
  color: #005e32;
  transition: all 0.3s ease;
}

.sidebar-selected {
  transition: all 0.5s ease !important;
  background-color: #f1f0ff !important;
  fill: #005e32 !important;
  color: #005e32 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active):hover
  svg {
  fill: #005e32;
  stroke: #005e32 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active {
  transition: all 0.5s ease;
  position: relative;
  margin-bottom: 10px;
  background-color: #f1f0ff;
  color: #005e32 !important;
  stroke: #005e32 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-title.active {
  transition: all 0.5s ease;
  position: relative;
  margin-bottom: 10px;
  background-color: #f1f0ff;
  color: #005e32 !important;
  stroke: #005e32 !important;
}

/* footer  */
.h-office {
  cursor: pointer;
}

.export-working {
  width: auto !important;
  max-width: 742px !important;
  text-align: right;
  margin: 0 auto;
}

.export-login {
  width: auto !important;
  max-width: 930px !important;
  text-align: right;
  margin: 0 auto;
}

.export-login-admin {
  width: auto !important;
  max-width: 1020px !important;
  text-align: right;
  margin: 0 auto;
}

.export-break {
  width: auto !important;
  max-width: 715px !important;
  text-align: right;
  margin: 0 auto;
}

.export-timesheet {
  width: auto !important;
  max-width: 1187px !important;
  text-align: right;
  margin: 0 auto;
}

.export-viewproject {
  width: auto !important;
  max-width: 996px !important;
  text-align: right;
  margin: 0 auto;
}

.export-viewtask {
  width: auto !important;
  max-width: 1030px !important;
  text-align: right;
  margin: 0 auto;
}

.export-missinglogout {
  width: auto !important;
  max-width: 780px !important;
  text-align: right;
  margin: 0 auto;
}

.export-approvalist {
  width: auto !important;
  max-width: 940px !important;
  text-align: right;
  margin: 0 auto;
}

.export-absence {
  width: auto !important;
  max-width: 1050px !important;
  text-align: right;
  margin: 0 auto;
}

.export-viewfinancial {
  width: auto !important;
  max-width: 100% !important;
  text-align: right;
  margin: 0 auto;
  padding: 0 10px;
}

.export-employeewise {
  width: auto !important;
  max-width: 1110px !important;
  text-align: right;
  margin: 0 auto;
}

.export-timesheet-employeewise {
  width: auto !important;
  max-width: 1010px !important;
  text-align: right;
  margin: 0 auto;
}

.export-timesheet-employeewise-admin {
  width: auto !important;
  max-width: 1110px !important;
  text-align: right;
  margin: 0 auto;
}

.export-employeewise-report {
  width: auto !important;
  max-width: 910px !important;
  text-align: right;
  margin: 0 auto;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active)
  svg {
  fill: red;
}

.profile-media > svg {
  fill: #212529 !important;
}

.formdiv-margin {
  margin-bottom: 0.5rem !important;
}

#cell-2-38 > div > div {
  word-wrap: break-word !important;
  overflow: hidden; /* Hide overflow content */
  white-space: nowrap;
}

/* pdf css  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --body-color: #9f180e;
  --secondary-color: rgb(233, 233, 233);
}

.red-color {
  color: #9f180e;
}

.bg-red {
  background-color: #9f180e;
}

.f-white {
  color: white;
}

.left-img-middle {
  width: 150px;
  height: 190px;
}

.top-portion {
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-portion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.midldle-left {
  display: flex;
  flex-direction: column;
  background-color: rgb(233, 233, 233) !important;
}

/* .common-para {
  margin-bottom: 0rem !important; 
  font-size: 13px !important;
} */

.common-header {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.1rem !important;
}

.seperator {
  border-width: 3px;
  color: var(--body-color) !important;
  border-radius: 8px;
  opacity: 5;
  margin-inline: 5px;
  margin-block: 0.2rem !important;
}

.margin-left-common {
  margin-left: 10px;
}

.gray-background {
  background-color: rgb(233, 233, 233);
}

/* right side */
.right-info {
  display: flex;
  align-items: center;
}

.right-info label {
  flex-basis: 90px;
  text-align: start;
}

.right-info-header {
  padding-block: 8px;
}

.para {
  font-size: 8px;
}

.td-pdf {
  padding: 0px !important;
}

.td-attendance {
  padding: 0.2rem;
  font-size: 8px !important;
}

.title-sheet {
  font-size: 7px !important;
  font-weight: bold !important;
}

/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
} */

tr:nth-child(even) {
  background-color: #dddddd;
}

.export-timesheet-task {
  width: auto;
  max-width: 975px;
  text-align: right;
  margin: 0 auto;
}

.custom-data-table.data-task {
  overflow: auto hidden;
  position: relative;
  left: 0;
}

.attendence-col > div {
  flex-wrap: nowrap;
}

.custom-scrollbar {
  overflow: hidden;
}

.padding-card {
  padding-right: 2rem !important;
}

.scrollable {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.fixed-height-card {
  height: 470px;
}

.fixed-height-workingchart {
  height: 520px;
}

.progress-chart > :nth-child(n) > div > svg > .apexcharts-title-text {
  font-size: 14px !important;
}

.chartbtn
  > .react-datepicker-wrapper
  > .react-datepicker__input-container
  > .example-custom-input {
  font-size: 12px;
  padding: 0.375rem 1.9rem;
  background-color: #7366ff !important;
  border-color: #7366ff !important;
  border-radius: 5px;
  color: white;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
}

.chartbtn
  > .react-datepicker__tab-loop
  > .react-datepicker-popper
  > div
  > .react-datepicker
  > button {
  background-color: #7366ff !important;
}
.chartbtn
  > .react-datepicker__tab-loop
  > .react-datepicker-popper
  > div
  > .react-datepicker
  > .react-datepicker__month-container
  > .react-datepicker__header {
  color: #7366ff !important;
}

.chartbtn
  > .react-datepicker__tab-loop
  > .react-datepicker-popper
  > div
  > .react-datepicker
  > .react-datepicker__month-container
  > .react-datepicker__month
  > .react-datepicker__month-wrapper
  > .react-datepicker__month-text--selected {
  background-color: #7366ff !important;
  color: white !important;
}

.chartbtn
  > .react-datepicker__tab-loop
  > .react-datepicker-popper
  > div
  > .react-datepicker
  > .react-datepicker__month-container
  > .react-datepicker__header
  > .react-datepicker__current-month {
  color: #7366ff !important;
}

.chartbtn
  > .react-datepicker__tab-loop
  > .react-datepicker-popper
  > div
  > .react-datepicker
  > .react-datepicker__month-container
  > .react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day--selected {
  background-color: #7366ff !important;
  color: white !important;
}

.fixed-height-card > .card-header {
  padding: 14px !important;
}

.fixed-height-card > .card-header > .row > .col-12 > h5 {
  font-size: 1.18rem !important;
}

.product-select > div > div {
  border: 3px solid #b6d8c5 !important;
  border-radius: 8px !important;
  box-shadow: 0 0 0 1px #9addae !important;
  color: #000 !important;
  padding: 0 !important;
  /* width: 230px !important; */
}

.product-select > div > div:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #9addae !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem #9addae !important;
}

.footer-header {
  font-size: 15px;
  font-weight: bold !important;
  color: #005e32 !important;
}

.table-column-adjust {
  overflow: auto;
  display: flex;
}

.badge-green-color {
  background-color: #005e32 !important;
  font-size: 0.85em !important;
}

/* media query start */

@media only screen and (min-width: 1000px) and (max-width: 1299px) {
  .custom-data-table.data-timesheetproject {
    overflow: unset !important;
    position: relative !important;
    left: 40px !important;
  }
  .custom-data-table.data-task {
    overflow: auto hidden !important;
    position: relative !important;
    left: 0 !important;
  }
  .custom-data-table.data-project {
    overflow: auto hidden !important;
    position: relative !important;
    left: 0 !important;
  }
  .custom-data-table.data-viewtask {
    overflow: unset !important;
    position: relative !important;
    left: 0 !important;
  }
  .custom-data-table.data-workingproject {
    overflow: auto hidden !important;
    position: relative !important;
    left: 0 !important;
  }
  .custom-data-table.data-workingtask {
    overflow: auto hidden !important;
    position: relative;
    left: 0px !important;
  }
  .custom-data-table.data-loginreport {
    overflow: auto hidden !important;
    position: relative !important;
    left: 0 !important;
  }
  .custom-data-table.data-breakreport {
    overflow: auto hidden !important;
    position: relative !important;
    left: 0 !important;
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1920px) {
  .custom-data-table.data-timesheetproject {
    overflow: auto hidden !important;
  }
}

@media only screen and (max-width: 600px) {
  /* Center the content horizontally */
  .attendence-col > div {
    flex-wrap: nowrap;
    justify-content: center;
  }
}

/* For devices larger than tablets and laptops */
@media (min-width: 1200px) {
  .tablet-laptop-br {
    display: block; /* Hide <br> tag for larger devices */
  }
}

/* For mobile devices */
@media (max-width: 600px) {
  .tablet-laptop-br {
    display: none; /* Hide <br> tag for larger devices */
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .tablet-laptop-br {
    display: block;
  }
}

@media (max-width: 1200px) {
  .input-user > div {
    width: 50%;
  }

  .input-project {
    width: 50%;
  }
}

@media (max-width: 512px) {
  .input-user > div {
    width: 65%;
  }

  .input-project {
    width: 65%;
  }
}

/* media query end */
